import React, { useCallback, useContext, useEffect, useState } from "react";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Helmet } from "react-helmet-async";
import {
    Alert,
    Badge,
    Button,
    Card,
    Col,
    Container,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import useAuth from "hooks/useAuth";
import NotyfContext from "contexts/NotyfContext";
import DatePicker from "react-datepicker";
import { SignaturesForm } from "components/ui/SignatureForm";
import { ClaimsDetailsForm } from "./sub-components/ClaimsDetailsForm";
import { QuotationListForm } from "./sub-components/QuotationListForm";
import { OtherDetails } from "components/common/works-order/OtherDetails";
import { ItemsForm } from "./sub-components/ItemsForm";
import { LoadingContext } from "App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { status } from "config";
import { zone_codes } from "config";
import { constructReport } from "utils/utilities";
import { AlertModal } from "components/AlertModal";
import * as quotationsApi from "@api/quotationsApi";
import * as claimsApi from "@api/claimsApi";
import * as schoolApi from "@api/schoolApi";

export const ClaimsForm = () => {
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const { action, invoiceEdit } = useParams();
    const create = action === "create";
    const { hasRole, user, config } = useAuth();
    const [rerender, setRerender] = useState(false);
    const { state } = useLocation();

    const tradeOptions = [
        {
            value: "BLDG",
            label: "Building Management",
        },
        {
            value: "PAS",
            label: "Plumbing and Sanitary Works",
        },
        {
            value: "HORTI",
            label: "Turf and Plant",
        },
    ];

    const hasRoleCollection = {
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isOM: hasRole("OPERATIONS-MANAGER"),
        isSCD: hasRole("SCD"),
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isAdmin: hasRole("ADMIN"),
        isInvoiceTeam: hasRole("INVOICE_TEAM"),
    };

    // eslint-disable-next-line
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    let schema = yup.object().shape({
        contacts: yup.object().shape({
            tel: yup.string().required("This field is required"),
            attention: yup.string().required("This field is required"),
            location: yup.string().required("This field is required"),
        }),
        fund_type: yup
            .object()
            .shape({
                value: yup.string(),
                label: yup.string(),
            })
            .nullable()
            .required("This field is required."),
        trade: yup
            .object()
            .shape({
                value: yup.string(),
                label: yup.string(),
            })
            .nullable()
            .required("This field is required."),
        zone: yup
            .object()
            .when("fund_type.value", {
                is: "MOE" || "95-5",
                then: yup
                    .object()
                    .shape({
                        value: yup.string(),
                        label: yup.string(),
                    })
                    .nullable()
                    .required("This field is required."),
            })
            .nullable(),
        zone_code: yup
            .object()
            .when("fund_type.value", {
                is: "MOE" || "95-5",
                then: yup
                    .object()
                    .shape({
                        value: yup.string(),
                        label: yup.string(),
                    })
                    .nullable()
                    .required("This field is required."),
            })
            .nullable(),
        school: yup.object().when(["fund_type.value", "job_type"], {
            is: (fundType, jobType) => {
                const isProjectWork =
                    jobType &&
                    jobType.some((obj) => obj.value === "project_work");

                if (
                    (fundType === "MOE" || fundType === "95-5") &&
                    isProjectWork
                ) {
                    return false;
                }
                return true;
            },
            then: yup.object().nullable().required("This field is required."),
        }),
        claim_date: yup
            .date()
            .required("This field is required")
            .nullable()
            .typeError("Must be a valid date"),
        job_type: yup
            .array()
            .min(1, "This field is required")
            .required("This field is required")
            .nullable(),
        job: yup.string().required("This field is required"),
        date_of_site_measurement: yup
            .date()
            .required("This field is required")
            .nullable()
            .typeError("Must be a valid date"),
    });

    const signatureDetailsSchema = {
        status_remarks: yup.string().when("status", {
            is: "REJECTED",
            then: yup.string().required("This field is required"),
        }),
    };

    const tcSchema = yup.object().shape({
        prepare_details: yup.object().shape({}),
    });

    const maSchema = yup.object().shape({
        ma_details: yup.object().shape(signatureDetailsSchema),
    });

    const invoiceSchema = yup.object().shape({
        invoice_number: yup.string().required("This field is required"),
        invoice_date: yup.string().required("This field is required"),
        payment_amount: yup.string().required("This field is required"),
    });

    const canEditInvoice = hasRoleCollection.isInvoiceTeam && invoiceEdit;

    switch (true) {
        case Boolean(canEditInvoice):
            schema = invoiceSchema;
            break;
        case hasRoleCollection.isTermContractor:
            schema = schema.concat(tcSchema);
            break;
        case hasRoleCollection.isManagingAgent:
            schema = maSchema;
            break;
        default:
            break;
    }

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        reset,
        watch,
        getValues,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
        defaultValues: {
            fund_type: { label: "School", value: "SCHOOL" },
        },
    });

    //
    // States
    //
    const [claimData, setClaimData] = useState({});
    // eslint-disable-next-line
    const [rates, setRates] = useState({ less_percent: 31, star_rate: 5 });
    const [pendingClaims, setPendingClaims] = useState([]);
    const [selectedRows, setSelectedRows] = useState("");
    const [defaultSelectedRows, setDefaultSelectedRows] = useState([]);
    const [resetItemsFlag, setResetItemsFlag] = useState(true);
    const [claimGst, setClaimGst] = useState(9);

    const [schoolsOriginal, setSchoolsOriginal] = useState([]);
    const [schools, setSchools] = useState([]);
    const [supportingDocuments, setSupportingDocuments] = useState([]);
    const [modalInfoCustom, setModalInfoCustom] = useState({
        notifMsg: "",
        open: false,
    });

    const fundType = useWatch({
        control,
        name: "fund_type",
    });

    const trade = useWatch({
        control,
        name: "trade",
    });

    const selectedSchool = useWatch({
        control,
        name: "school",
    });

    const selectedZone = useWatch({
        control,
        name: "zone",
    });

    const jobType = useWatch({
        control,
        name: "job_type",
    });

    const optionsFund = [
        {
            label: "School",
            value: "SCHOOL",
        },
        {
            label: "MOE",
            value: "MOE",
        },
        {
            label: "95-5",
            value: "95-5",
        },
    ];

    const maStatus = watch("ma_details.status", "");

    //
    // Functions
    //

    const maApprovalApi = async ({ ma_details }) => {
        if (!ma_details.signature && maStatus !== "REJECTED") {
            notyf.open({
                type: "warning",
                message: "Please provide signature to proceed.",
            });
            return;
        }

        setIsLoadingActive(true);

        const formData = new FormData();

        const supportingDocumentsData =
            supportingDocuments?.getFilesData() || [];

        formData.append("signature_details", ma_details.signature_details);
        formData.append("signature", ma_details.signature);
        formData.append("signature_options", "my_signature");

        formData.append("status", ma_details.status);
        formData.append("remarks", ma_details.status_remarks);

        ma_details?.next_approval &&
            formData.append("next_approval", ma_details?.next_approval?.value);

        formData.append(
            "approval_level",
            claimData?.pending_approval[user?.id]?.approval_level
        );

        supportingDocumentsData.forEach((file) => {
            formData.append("attachments[]", file.rawFile);
        });

        try {
            const response = await claimsApi.maClaimsApproval(action, formData);

            if (response.status === 201 || response.status === 200) {
                notyf.open({
                    type: "success",
                    message: `Successfully ${
                        ma_details.status === "REJECTED"
                            ? "rejected"
                            : "endorsed"
                    } claims`,
                });
                navigate("/works-order/claims");
                setIsLoadingActive(false);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
            setIsLoadingActive(false);
        }
    };

    const validateData = (data, status) => {
        if (!user?.contractor) {
            notyf.open({
                type: "Danger",
                message: `Please contact administrator to give your account a contractor role.`,
            });
            return false;
        }

        if (!data.prepare_details.signature && status !== "DRAFT") {
            notyf.open({
                type: "warning",
                message: "Please provide signature to proceed.",
            });
            return false;
        }

        const isClaimPercentValid = claimPercentageValidation(data);

        if (!isClaimPercentValid) {
            notyf.open({
                type: "warning",
                message: "Please update the claim percentage to proceed.",
            });
            return false;
        }

        if (!data.quotation_items.length) {
            notyf.open({
                type: "warning",
                message: "Please select a quotation.",
            });
            return false;
        }

        return true;
    };

    const claimsApiCall = async (data, status) => {
        const cleanedQuotationItems = data.quotation_items.map(
            ({ items, ...quotation_items_rest }) => ({
                ...quotation_items_rest,
                items:
                    items &&
                    items.map(({ is_focus, ...items_rest }) => items_rest),
            })
        );

        setIsLoadingActive(true);

        const formData = new FormData();

        const supportingDocumentsData =
            supportingDocuments.getFilesData() || [];

        const contacts = JSON.stringify(data.contacts);
        const job_type = JSON.stringify(data.job_type);

        formData.append(
            "source_fund",
            data.fund_type?.value || data?.fund_type
        );
        formData.append("trade", data?.trade?.value || data?.trade);

        if (
            data.fund_type?.value === "SCHOOL" ||
            (["MOE", "95-5"].includes(data.fund_type?.value) &&
                !data?.job_type.some((obj) => obj.value === "project_work"))
        ) {
            formData.append("school_id", data?.school?.value);
        }

        if (
            data.fund_type?.value === "MOE" ||
            data.fund_type?.value === "95-5"
        ) {
            formData.append("zone", data?.zone?.value);
            formData.append("zone_code", data?.zone_code?.value);
        }
        formData.append("contacts", contacts);
        formData.append(
            "date",
            new Date(data.claim_date).toLocaleDateString("en-CA")
        );

        job_type && formData.append("job_type", job_type);
        formData.append("job", data.job);
        formData.append("contractor", user?.contractor);
        formData.append(
            "date_of_site_measurement",
            new Date(data.date_of_site_measurement).toLocaleDateString("en-CA")
        );

        formData.append(
            "claim_quotations",
            JSON.stringify(cleanedQuotationItems)
        );

        formData.append("remarks", data.remarks || "");
        formData.append(
            "gst",
            claimGst
        );

        formData.append("total_amount", data.grand_totals.total_amount);
        formData.append("sub_total_amount", data.grand_totals.sub_total_amount);
        formData.append("total_gst", data.grand_totals.total_gst);
        formData.append("status", status);

        supportingDocumentsData.forEach((file) => {
            formData.append("supporting_documents[]", file.rawFile);
        });

        if (status !== "DRAFT") {
            data.prepare_details.signature_details &&
                formData.append(
                    "signature_details",
                    data.prepare_details.signature_details || false
                );

            formData.append("signature_options", "my_signature");
            formData.append("signature", data.prepare_details.signature);
        }

        try {
            const response =
                action === "create"
                    ? await claimsApi.createClaim(formData)
                    : await claimsApi.updateClaim(action, formData);

            if (response.status === 201 || response.status === 200) {
                notyf.open({
                    type: "success",
                    message: `Successfully ${
                        action === "create" ? "created" : "updated"
                    } claims`,
                });
                navigate("/works-order/claims");
                setIsLoadingActive(false);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: error,
            });
            setIsLoadingActive(false);
        }
    };

    const invoiceUpdate = async (data) => {
        try {
            setIsLoadingActive(true);

            const formData = new FormData();
            const supportingDocumentsData =
                supportingDocuments.getFilesData() || [];

            supportingDocumentsData.forEach((file) => {
                formData.append("supporting_documents[]", file.rawFile);
            });
            formData.append("invoice_number", data.invoice_number);
            formData.append(
                "invoice_date",
                new Date(data.invoice_date).toLocaleDateString("en-CA")
            );
            formData.append("payment_amount", data.payment_amount);

            const response = await claimsApi.invoiceUpdate(action, formData);

            if (response.status === 201 || response.status === 200) {
                notyf.open({
                    type: "success",
                    message: `Successfully update invoice details`,
                });
                navigate("/works-order/claims");
                setIsLoadingActive(false);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: error,
            });
            setIsLoadingActive(false);
        }
    };

    const claimPercentageValidation = (data) => {
        let isNotEmpty = true;

        for (let i = 0; i < data.quotation_items.length; i++) {
            if (
                !data.quotation_items[i].claim_percentage ||
                data.quotation_items[i].claim_percentage === "0"
            ) {
                isNotEmpty = false;
                break;
            }
        }
        return isNotEmpty;
    };

    const getPendingClaims = useCallback(async (param = {}) => {
        try {
            setIsLoadingActive(true);

            const response = await quotationsApi.getPendingClaims(param);
            if (response.status === 200) {
                setPendingClaims(response.data);
                setIsLoadingActive(false);
            }
        } catch (error) {
            setIsLoadingActive(false);
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
        }
    }, []);

    const getClaim = useCallback(async () => {
        try {
            if (!schools.length) return;
            setIsLoadingActive(true);
            const response = await claimsApi.getClaim(action);

            if (response.status === 200) {
                const data = response.data.data;
                const rowIds = {};

                data.quotationItems.map((e) => {
                    return (rowIds[e.id] = true);
                });

                setDefaultSelectedRows(rowIds);
                await setReset(data, schools);

                setClaimData(data);
                setClaimGst(data?.gst);
                setIsLoadingActive(false);
            }
        } catch (error) {
            notyf.open({
                type: "danger",
                message: "Something went wrong with the server",
            });
            setIsLoadingActive(false);
        }

        // eslint-disable-next-line
    }, [action, notyf, reset, schools]);

    const setReset = (data, schoolsMapped) => {
        setSelectedRows(JSON.stringify(data.quotationItems));
        const zoneCodeOption = zone_codes(data.zone);

        reset({
            fund_type:
                action === "create" ||
                ["DRAFT", "REJECTED"].includes(data.status)
                    ? optionsFund.find((o) => o.value === data.source_fund)
                    : optionsFund.find((o) => o.value === data.source_fund)
                          .value,
            trade:
                action === "create" ||
                ["DRAFT", "REJECTED"].includes(data.status)
                    ? tradeOptions.find((o) => o.value === data.trade)
                    : tradeOptions.find((o) => o.value === data.trade).value,

            school:
                action === "create" ||
                ["DRAFT", "REJECTED"].includes(data.status)
                    ? schoolsMapped?.find((o) => o.value === data.school_id)
                    : schoolsMapped?.find((o) => o.value === data.school_id)
                          ?.value,

            contacts: {
                attention: data.contacts.attention || "",
                cc: data.contacts.cc || "",
                fax: data.contacts.fax || "",
                location: data.contacts.location || "",
                tel: data.contacts.tel || "",
            },

            claim_date: new Date(data.date),
            job: data.job || "",
            job_type: data?.job_type || [{ label: "-" }],
            remarks: data?.remarks || "-",

            date_of_site_measurement: new Date(
                data?.site_measurement?.date_of_site_measurement
            ),

            zone: user?.zone_in_charge?.find((o) => o.value === data.zone) || {
                value: "",
                label: "-",
            },
            zone_code: zoneCodeOption[0],

            prepare_details: {
                signature_options: data.prepare_details?.signature_options,
                signature_details: data.prepare_details?.signature_details,
                signature: data.prepare_signature,
                name: data.prepare_details?.name,
            },

            ma_details: data.approvals?.MA?.details
                ? {
                      signature_options:
                          data.approvals?.MA?.details.signature_options,
                      signature_details:
                          data.approvals?.MA?.details.signature_details,
                      name: data.approvals?.MA?.details?.name,
                      signature: data.approvals?.MA?.signature,
                      status: data.approvals?.MA?.status,
                      status_remarks: data.approvals?.MA?.remarks || "",
                  }
                : {
                      status:
                          data.isAcknowledged || data.zone === "SOUTH"
                              ? "VERIFIED"
                              : "ACKNOWLEDGE",
                  },

            invoice_number: data.invoice_number,
            invoice_date: data.invoice_date ? new Date(data.invoice_date) : "",
            payment_amount: data.payment_amount,
        });
    };

    const parseAttachments = (attachments) => {
        let parsedAttachments = [];

        Object.entries(attachments).forEach(([key, value]) => {
            if (value) {
                const valueData = value.map((v) => ({
                    ...v,
                    uploader: key.toUpperCase(),
                }));
                parsedAttachments = [...parsedAttachments, ...valueData];
            }
        });

        return parsedAttachments;
    };

    const resetClaims = () => {
        setResetItemsFlag(true);
    };

    const populateOMInfo = (data) => {
        setValue(
            "school",
            schools.find((o) => o.value === data?.id)
        );

        const getOMs = data.oms;

        const attentionOM = getOMs[0]?.full_name || "";
        let ccOM = "";
        const telephoneOM = data?.telephone || "";
        const faxOM = data?.fax || "";
        const addressOM = data?.address || "";

        getOMs.map((om, index) => {
            if (index === 0) return;

            if (index === 1) {
                ccOM = om.full_name;
            } else {
                ccOM = `${ccOM}, ${om.full_name}`;
            }
        });

        setValue("contacts.cc", ccOM);
        setValue("contacts.attention", attentionOM);
        setValue("contacts.location", addressOM);
        setValue("contacts.tel", telephoneOM);
        setValue("contacts.fax", faxOM);

        setRates({
            less_percent: data.less_percent,
            star_rate: data.star_rate,
        });
    };

    const deleteAttachmentClaim = (id) => {
        const cloneClaimData = { ...claimData };
        const documents = cloneClaimData?.documents || {};

        for (let key in documents) {
            if (Array.isArray(documents[key])) {
                documents[key] = documents[key].filter(
                    (item) => item.id !== id
                );
            }
        }

        claimData.documents = documents;
        setClaimData(cloneClaimData);
    };

    //
    // UseEffects
    //

    useEffect(() => {
        if (action !== "create") {
            getClaim();
        }
    }, [action, getClaim, rerender]);

    useEffect(() => {
        if (fundType && trade) {
            const param = {
                trade: trade?.value,
                claim_id: claimData?.id,
            };

            if (fundType.value === "SCHOOL" && selectedSchool)
                param.school = selectedSchool.value;

            if (fundType.value === "MOE" && selectedZone)
                param.zone = selectedZone.value;

            if (
                fundType.value === "MOE" &&
                jobType &&
                !jobType.some((obj) => obj.value === "project_work")
            ) {
                delete param.zone;
                param.school = selectedSchool?.value;
            }

            if (action === "create" || claimData?.id) {
                getPendingClaims(param);
            }
        }
    }, [
        getPendingClaims,
        fundType,
        trade,
        selectedSchool,
        selectedZone,
        jobType,
        claimData,
    ]);

    useEffect(() => {
        async function callAsync() {
            const schoolResponse = await schoolApi.getSchools();
            const schoolsMapped = schoolResponse.data.data.map((field) => {
                return { label: field.name, value: field.id };
            });

            setSchoolsOriginal(schoolResponse.data.data);
            setSchools(schoolsMapped);
        }

        callAsync();
    }, []);

    useEffect(() => {
        if (
            Object.keys(pendingClaims).length &&
            Object.keys(claimData).length &&
            Object.keys(defaultSelectedRows).length
        ) {
            const result = claimData["claim_quotations"].filter((obj) =>
                Object.keys(defaultSelectedRows).includes(obj.quotation_id)
            );

            pendingClaims?.data.forEach((item1) => {
                let item2 = result.find(
                    (item2) => item2.quotation_id === item1.id
                );

                if (item2) {
                    item1.claim_percentage = item2.claim_percentage;
                }
            });
        }
    }, [pendingClaims, claimData, defaultSelectedRows]);

    // Clone data from quotation
    useEffect(() => {
        if (state && schools.length && schoolsOriginal.length) {
            reset({
                fund_type: optionsFund.find(
                    (o) => o.value === state?.fund_type
                ),
                job_type: state?.job_type,
                trade: tradeOptions?.find((o) => o.value === state?.trade),
                school: schools?.find((o) => o.value === state?.school_id),
                job: state?.job,
            });

            const selectSchool = schoolsOriginal?.find(
                (o) => o.id === state?.school_id
            );
            populateOMInfo(selectSchool);
            setDefaultSelectedRows({ [state?.id]: true });
        }
    }, [state, schools]);

    //
    // Conditions
    //

    const maUserValidation = Boolean(
        claimData?.pending_approval && claimData?.pending_approval[user?.id]
    );

    const canView =
        // TC condition
        ((((hasRoleCollection.isTermContractor ||
            hasRoleCollection.isInvoiceTeam) &&
            (["PENDING", "COMPLETED", "APPROVED", "VOID"].includes(
                claimData?.status
            ) ||
                !claimData?.status)) ||
            // MA condition
            (hasRoleCollection.isManagingAgent &&
                (["REJECTED", "COMPLETED", "APPROVED"].includes(
                    claimData?.status
                ) ||
                    claimData?.approvals?.MA))) &&
            action !== "create") ||
        hasRoleCollection.isAdmin ||
        hasRoleCollection.isOM;

    const canSign =
        // TC condition
        ((hasRoleCollection.isTermContractor &&
            ["REJECTED", "DRAFT", "APPROVED"].includes(claimData?.status)) ||
            // MA condition
            (hasRoleCollection.isManagingAgent &&
                (["PENDING"].includes(claimData?.status) ||
                    !claimData?.status))) &&
        action !== "create";

    const canUpload =
        // TC condition
        (hasRoleCollection.isTermContractor &&
            (action === "create" ||
                ["REJECTED", "DRAFT"].includes(claimData?.status))) ||
        // MA condition
        (hasRoleCollection.isManagingAgent &&
            ["PENDING"].includes(claimData?.status) &&
            maUserValidation) ||
        // INVOICE TEAM condition
        canEditInvoice;

    const canEditDraftOrReject =
        (hasRoleCollection.isTermContractor &&
            ["REJECTED", "DRAFT"].includes(claimData?.status)) ||
        action === "create";

    return (
        <React.Fragment>
            <Helmet
                title={`${
                    create
                        ? "Create"
                        : canView
                        ? "View"
                        : canSign
                        ? "Sign"
                        : "Edit"
                } claim`}
            />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h4 className="h4 mb-3">
                            {create
                                ? "Create"
                                : canView
                                ? "View"
                                : canSign
                                ? "Sign"
                                : "Edit"}{" "}
                            claim
                        </h4>
                    </Col>
                    <Col md={6}>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() => navigate("/works-order/claims")}
                            >
                                Claims list
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {create
                                    ? "Create"
                                    : canView
                                    ? "View"
                                    : canSign
                                    ? "Sign"
                                    : "Edit"}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        {action !== "create" && (
                            <Alert
                                key="primary"
                                variant="primary"
                                className="p-3 w-100"
                                style={{ display: "block" }}
                            >
                                <Alert.Heading
                                    style={{ fontSize: "15px" }}
                                    className="mb-3"
                                >
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        size="lg"
                                        className="me-2"
                                        color="gray"
                                    />
                                    Work completion information
                                </Alert.Heading>
                                <Row>
                                    <Col md={4}>
                                        <div>
                                            <span className="font-weight-bold me-2">
                                                Reference number
                                            </span>

                                            <div className="mb-2">
                                                {claimData?.reference_number}
                                            </div>
                                        </div>
                                        <div>
                                            <span className="font-weight-bold me-2">
                                                Claim status
                                            </span>

                                            <div className="mb-2">
                                                {claimData?.display_status ? (
                                                    <Badge
                                                        pill
                                                        bg={status(
                                                            claimData.display_status
                                                        )}
                                                    >
                                                        {
                                                            claimData?.display_status
                                                        }
                                                    </Badge>
                                                ) : (
                                                    "-"
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div>
                                            <span className="font-weight-bold me-2">
                                                Invoice number
                                            </span>

                                            <Controller
                                                control={control}
                                                name="invoice_number"
                                                render={({
                                                    field: { value, onChange },
                                                }) =>
                                                    canEditInvoice ? (
                                                        <Form.Control
                                                            className={`form-box form-control ${
                                                                errors?.invoice_number &&
                                                                "is-invalid"
                                                            }`}
                                                            type="text"
                                                            value={value || ""}
                                                            onChange={onChange}
                                                        />
                                                    ) : (
                                                        <p
                                                            style={{
                                                                fontWeight:
                                                                    "300px",
                                                            }}
                                                        >
                                                            {value || "-"}
                                                        </p>
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <span className="font-weight-bold me-2">
                                                Invoice amount
                                            </span>

                                            <Controller
                                                control={control}
                                                name="payment_amount"
                                                render={({
                                                    field: { value, onChange },
                                                }) =>
                                                    canEditInvoice ? (
                                                        <Form.Control
                                                            className={`form-box form-control ${
                                                                errors?.payment_amount &&
                                                                "is-invalid"
                                                            }`}
                                                            type="text"
                                                            value={value || ""}
                                                            onChange={onChange}
                                                        />
                                                    ) : (
                                                        <p
                                                            style={{
                                                                fontWeight:
                                                                    "300px",
                                                            }}
                                                        >
                                                            {value
                                                                ? `$ ${value}`
                                                                : "-"}
                                                        </p>
                                                    )
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div>
                                            <span className="font-weight-bold me-2">
                                                Invoice date
                                            </span>

                                            <Controller
                                                control={control}
                                                name={`invoice_date`}
                                                defaultValue=""
                                                render={({
                                                    field: { value, onChange },
                                                }) =>
                                                    canEditInvoice ? (
                                                        <DatePicker
                                                            dateFormat="yyyy-MM-dd"
                                                            selected={value}
                                                            onChange={onChange}
                                                            value={value}
                                                            className="form-box form-control"
                                                        />
                                                    ) : (
                                                        <p
                                                            style={{
                                                                fontWeight:
                                                                    "300px",
                                                            }}
                                                        >
                                                            {value
                                                                ? new Date(
                                                                      value
                                                                  ).toLocaleDateString(
                                                                      "en-CA"
                                                                  )
                                                                : "-"}
                                                        </p>
                                                    )
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Alert>
                        )}
                        <Form>
                            <ClaimsDetailsForm
                                control={control}
                                errors={errors}
                                fundType={fundType}
                                claimData={claimData}
                                view={
                                    canView || hasRoleCollection.isManagingAgent
                                }
                                schools={schools}
                                schoolsOriginal={schoolsOriginal}
                                jobType={jobType}
                                populateOMInfo={populateOMInfo}
                            />
                            <div
                                className={
                                    (canView || claimData.status !== "DRAFT") &&
                                    ["PENDING"].includes(claimData?.status)
                                        ? "d-none"
                                        : ""
                                }
                            >
                                {canEditDraftOrReject && (
                                    <>
                                        <Row className="mt-4 mb-3">
                                            <Col md={12}>
                                                <h5>Quotations</h5>
                                            </Col>
                                        </Row>

                                        <QuotationListForm
                                            control={control}
                                            errors={errors}
                                            fundType={fundType}
                                            jobType={jobType}
                                            pendingClaims={pendingClaims}
                                            setSelectedRows={
                                                resetItemsFlag === false
                                                    ? resetItemsFlag
                                                    : setSelectedRows
                                            }
                                            defaultSelectedRows={
                                                defaultSelectedRows
                                            }
                                            withCheckbox={resetItemsFlag}
                                        />
                                    </>
                                )}
                            </div>
                            <Row className="mt-4 mb-3">
                                <Col md={12}>
                                    <Row>
                                        <Col md={6}>
                                            <h5>Quotation items</h5>
                                        </Col>
                                        {!resetItemsFlag &&
                                            canEditDraftOrReject && (
                                                <Col md={6}>
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip
                                                                id={`tooltip-top`}
                                                            >
                                                                Reset to
                                                                original
                                                                quotation items
                                                            </Tooltip>
                                                        }
                                                        placement="top"
                                                    >
                                                        <Button
                                                            className="form-box float-end"
                                                            variant="primary"
                                                            onClick={() =>
                                                                resetClaims()
                                                            }
                                                        >
                                                            Reset quotation
                                                            items
                                                        </Button>
                                                    </OverlayTrigger>
                                                </Col>
                                            )}
                                    </Row>
                                </Col>
                            </Row>

                            {(claimData?.gst !== undefined ||
                                action === "create") && (
                                <ItemsForm
                                    control={control}
                                    errors={errors}
                                    selectedRows={
                                        selectedRows.length
                                            ? JSON.parse(selectedRows)
                                            : []
                                    }
                                    setValue={setValue}
                                    view={
                                        canView ||
                                        hasRoleCollection.isManagingAgent
                                    }
                                    sign={
                                        canSign ||
                                        hasRoleCollection.isManagingAgent
                                    }
                                    gstConfig={claimGst}
                                    setSelectedRows={setSelectedRows}
                                    setResetItemsFlag={setResetItemsFlag}
                                    resetItemsFlag={resetItemsFlag}
                                    setClaimGst={setClaimGst}
                                />
                            )}

                            <OtherDetails
                                isClaims
                                canVerify={
                                    claimData?.isAcknowledged ||
                                    claimData?.zone === "SOUTH"
                                }
                                control={control}
                                document={
                                    claimData?.documents
                                        ? parseAttachments(claimData?.documents)
                                        : []
                                }
                                errors={errors}
                                hasRoleCollection={hasRoleCollection}
                                setUpload={setSupportingDocuments}
                                setValue={setValue}
                                histories={claimData?.histories}
                                view={canView}
                                canUpload={canUpload}
                                canDelete={
                                    hasRoleCollection.isTermContractor &&
                                    (action === "create" ||
                                        ["DRAFT", "REJECTED"].includes(
                                            claimData?.status
                                        ))
                                }
                                canEditInvoice={Boolean(canEditInvoice)}
                                maUserValidation={maUserValidation}
                                deleteAttachmentCallback={deleteAttachmentClaim}
                                tcRemarks={claimData?.remarks}
                            />
                            <Row className="mt-5">
                                {(Object.keys(claimData).length ||
                                    action === "create") && (
                                    <>
                                        <SignaturesForm
                                            control={control}
                                            canSign={
                                                !canView &&
                                                hasRoleCollection.isTermContractor
                                            }
                                            label="Prepared by"
                                            name="prepare_details"
                                            setValue={setValue}
                                        />

                                        <SignaturesForm
                                            control={control}
                                            canSign={
                                                !canView &&
                                                hasRoleCollection.isManagingAgent &&
                                                maUserValidation
                                            }
                                            label="Checked & certified correct by"
                                            name="ma_details"
                                            setValue={setValue}
                                        />
                                    </>
                                )}
                            </Row>
                            <Row className="mt-4">
                                <Col md={12} className="text-center">
                                    {hasRoleCollection.isManagingAgent &&
                                        maUserValidation &&
                                        ["PENDING"].includes(
                                            claimData?.status
                                        ) && (
                                            <Button
                                                className="me-2"
                                                onClick={handleSubmit(
                                                    (data) => {
                                                        if (
                                                            data.ma_details
                                                                .status ===
                                                            "VERIFIED"
                                                        ) {
                                                            setModalInfoCustom({
                                                                notifMsg: (
                                                                    <span>
                                                                        Are you
                                                                        sure you
                                                                        want to
                                                                        <strong>
                                                                            {" "}
                                                                            VERIFY{" "}
                                                                        </strong>
                                                                        this
                                                                        claim?{" "}
                                                                        <br />
                                                                        If yes,
                                                                        this
                                                                        will be
                                                                        verified
                                                                        and
                                                                        forwarded
                                                                        to TC
                                                                        for
                                                                        approval.
                                                                    </span>
                                                                ),
                                                                open: true,
                                                            });
                                                            return;
                                                        }

                                                        maApprovalApi(data);
                                                    }
                                                )}
                                                type="submit"
                                                variant="primary"
                                            >
                                                Submit
                                            </Button>
                                        )}

                                    {hasRoleCollection.isTermContractor &&
                                        (["DRAFT", "REJECTED"].includes(
                                            claimData?.status
                                        ) ||
                                            action === "create") && (
                                            <Button
                                                className="me-2"
                                                variant="primary"
                                                type="submit"
                                                onClick={handleSubmit(() => {
                                                    const values = getValues();
                                                    const isValid =
                                                        validateData(
                                                            values,
                                                            "PENDING"
                                                        );

                                                    if (isValid) {
                                                        claimsApiCall(
                                                            values,
                                                            "PENDING"
                                                        );
                                                    }
                                                })}
                                            >
                                                Submit
                                            </Button>
                                        )}

                                    {canEditInvoice && (
                                        <Button
                                            className="me-2"
                                            variant="primary"
                                            type="submit"
                                            onClick={handleSubmit((data) => {
                                                invoiceUpdate(data);
                                            })}
                                        >
                                            Update invoice
                                        </Button>
                                    )}

                                    {(action === "create" ||
                                        ["DRAFT", "REJECTED"].includes(
                                            claimData?.status
                                        )) && (
                                        <Button
                                            className="me-2"
                                            onClick={handleSubmit(() => {
                                                const values = getValues();
                                                const isValid = validateData(
                                                    values,
                                                    "DRAFT"
                                                );

                                                if (isValid) {
                                                    claimsApiCall(
                                                        values,
                                                        "DRAFT"
                                                    );
                                                }
                                            })}
                                            type="submit"
                                            variant="secondary"
                                        >
                                            Draft
                                        </Button>
                                    )}
                                    {claimData?.id !== "" &&
                                        claimData?.id !== null &&
                                        claimData?.id !== undefined &&
                                        claimData?.file_info?.bucket &&
                                        claimData?.file_info?.file_path && (
                                            <Button
                                                variant="link"
                                                onClick={() =>
                                                    window.open(
                                                        constructReport(
                                                            claimData?.file_info
                                                                ?.bucket,
                                                            claimData?.file_info
                                                                ?.file_path,
                                                            `${claimData?.reference_number}-${claimData?.date}`,
                                                            true
                                                        )
                                                    )
                                                }
                                            >
                                                Preview
                                            </Button>
                                        )}
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            navigate("/works-order/claims")
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
            <AlertModal
                api={handleSubmit((data) => maApprovalApi(data))}
                modalInfo={modalInfoCustom}
                setModalInfo={setModalInfoCustom}
                size="md"
                confirmText="Yes"
                cancelText="No"
            />
        </React.Fragment>
    );
};
