import React, { useState, useContext } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    InputGroup,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
// import { ErrorMessage } from '@hookform/error-message';
import * as deliverablesApi from "../../api/deliverablesApi";
import NotyfContext from "@contexts/NotyfContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    formatFileName,
    moneyFormatter,
    scrollToTop,
    toTitleCase,
} from "../../utils/utilities";
import { useEffect } from "react";
import { useCallback } from "react";

import { deleteAttachment } from "../../api/attachmentApi";
import { DeleteModal } from "../../components/DeleteModal";
import useAuth from "../../hooks/useAuth";
// import Select from 'react-select';
import {
    faDownload,
    faFile,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DeliverableStatusHistory } from "./DeliverableStatusHistory";
import { SignaturesForm } from "../../components/ui/SignatureForm";

const schema = yup.object().shape({
    // zone: yup.mixed().required("This field is required").nullable(),
    // month_year: yup.mixed().required("This field is required").nullable(),
});
export default function DeliverableForm({ category }) {
    let pageTitle = "";
    let listLink = "";
    if (category.toLowerCase() === "bm") {
        pageTitle = "Building maintenance";
        listLink = "/deliverables/building-maintenance";
    }
    if (category.toLowerCase() === "tp") {
        pageTitle = "Turf and plant";
        listLink = "/deliverables/turf-and-plant";
    }
    const { state } = useLocation();
    const { hasRole } = useAuth();

    const notyf = useContext(NotyfContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const create = id ? false : true;
    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState();
    const [otherAttachments, setOtherAttachments] = useState([]);
    const [customCount, setCustomCount] = useState(0);
    const [deliverable, setDeliverable] = useState({});
    const [deliverableSummary, setDeliverableSummary] = useState({});

    // const [disableField, setDisableField] = useState(false);
    const [modalInfo, setModalInfo] = useState({
        key: null,
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    let incompleteAttachment = false;

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });
    const tcSignatureOption = watch("prepared_by.select_signature", "");
    const maSignatureOption = watch("approved_by.select_signature", "");

    const hasRoleCollection = {
        isTermContractor: hasRole("TERM-CONTRACTOR"),
        isManagingAgent: hasRole("MANAGING-AGENT"),
        isOM: hasRole("OPERATIONS-MANAGER"),
        isQS: hasRole("MA-QS"),
        isAdmin: hasRole("ADMIN"),
    };
    const updateDeliverable = async (data, status) => {
        if (status === "SUBMITTED") {
            validateAttachment();
        }

        //return
        if (incompleteAttachment) {
            scrollToTop();
            notyf.open({
                type: "danger",
                message: "Incomplete attachment",
            });
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("zone", deliverable?.zone);
        formData.append("month", deliverable.month);
        formData.append("year", deliverable.year);
        formData.append("status", status);
        formData.append("category", category);
        if (status === "SUBMITTED" || status === "REJECTED") {
            formData.append("signature", data?.prepared_by?.signature);
            formData.append(
                "signature_details",
                data?.prepared_by?.signature_details
            );
        }

        if (status === "DRAFT" || status === "SUBMITTED") {
            attachments.forEach((item) => {
                let files = item?.upload_data?.getFilesData();
                if (files?.length > 0) {
                    files.forEach((file) => {
                        formData.append(item.key + "[]", file.rawFile);
                    });
                }
            });

            otherAttachments.forEach((item) => {
                let files = item?.upload_data?.getFilesData();
                if (files.length > 0) {
                    files.forEach((file) => {
                        formData.append(
                            "other_documents[" + item.key + "]",
                            file.rawFile
                        );
                    });
                    formData.append(
                        "other_remarks[" + item.key + "]",
                        item.remarks
                    );
                }
            });
        }

        try {
            const response = await deliverablesApi.updateDeliverable(
                formData,
                id
            );
            setLoading(false);
            if (response.data.status === "SUCCESS") {
                let message = "";
                if (status === "SUBMITTED") {
                    message = "Deliverables has been submitted for approval.";
                } else {
                    message = "Deliverables has been updated";
                }
                notyf.open({
                    type: "success",
                    message: message,
                });
                if (status === "SUBMITTED") {
                    navigate(listLink);
                }
                window.location.reload();
            }
        } catch (error) {
            setLoading(false);
            notyf.open({
                type: "danger",
                message:
                    error.response.status + " " + error.response.statusText,
            });
        }
    };

    const validateAttachment = () => {
        attachments?.forEach((item) => {
            if (item.required) {
                let files = item?.upload_data?.getFilesData().length;
                let attachments = item?.attachments?.length;

                if (create) {
                    item["file_count"] = files;
                    if (files < 1) {
                        incompleteAttachment = true;
                    } else {
                        incompleteAttachment = false;
                    }
                } else {
                    item["file_count"] = files + attachments;

                    if (attachments < 1 && files < 1) {
                        incompleteAttachment = true;
                    }

                    if (attachments > 1 && files < 1) {
                        incompleteAttachment = false;
                    }

                    if (attachments < 1 && files > 1) {
                        incompleteAttachment = false;
                    }
                }
            }
        });
    };

    const addMore = () => {
        setOtherAttachments((prevState) => [
            ...prevState,
            {
                key: "custom_" + customCount,
                remarks: "",
                required: false,
                custom: true,
                placeholder: "Enter report title ...",
            },
        ]);
        setCustomCount((prev) => prev + 1);
    };

    const remove = (index) => {
        let temp_other_attachment = [...otherAttachments];
        temp_other_attachment.splice(index, 1);
        setOtherAttachments(temp_other_attachment);
    };

    const draftHandler = (data) => {
        updateDeliverable(data, "DRAFT");
    };

    const submitHandler = (data) => {
        let error = "";
        if (
            !data.prepared_by?.select_signature ||
            !data.prepared_by?.signature
        ) {
            error += "Signature is required \n";
        }

        if (
            !data.prepared_by?.signature ||
            !data.prepared_by?.select_signature
        ) {
            notyf.open({
                type: "danger",
                message: error,
            });
            return;
        }
        updateDeliverable(data, "SUBMITTED");
    };

    const confirmHandler = async (data) => {
        let error = "";
        if (
            !data.approved_by?.select_signature ||
            !data.approved_by?.signature
        ) {
            error += "Signature is required \n";
        }

        if (!data.approval_status) {
            error += "Approval status is required \n";
        }

        if (
            (!data.approval_status ||
                !data.approved_by?.signature ||
                !data.approved_by?.select_signature) &&
            data.approval_status === "APPROVED"
        ) {
            notyf.open({
                type: "danger",
                message: error,
            });
            return;
        }
        data.user_type = "MA";
        const approvalData = {
            status: data.approval_status,
            user_type: "MA",
            remarks: data.reject_remarks,
            signature: data.approved_by?.signature,
            signature_details: data.approved_by?.signature_details,
            signature_options: data.approved_by?.signature_options,
            zone: data?.zone,
        };

        await deliverablesApi.approval(id, approvalData);
        let message = "";
        if (data.approval_status === "APPROVED") {
            message = "Deliverables has been approved.";
        } else {
            message = "Deliverables has been rejected";
        }
        notyf.open({
            type: "success",
            message: message,
        });

        navigate(listLink);
    };

    const getDeliverables = useCallback(async () => {
        const sectionResponse = await deliverablesApi.getDeliverableSections(
            category
        );
        const response = await deliverablesApi.getDeliverable(id, {
            category: category,
        });
        const data = response?.data?.data;

        const temp_attachments = [...sectionResponse?.data?.data];
        temp_attachments.forEach((value, key2) => {
            if (
                value.key === "tp-monthly-job-status" ||
                value.key === "tp-status-expenditure"
            ) {
                value.required = false;
            }
            value["attachments"] = [];
        });
        Object.keys(data?.attachments).forEach((key) => {
            data.attachments[key].forEach((item, index) => {
                temp_attachments.forEach((value, key2) => {
                    if (key === value.key) {
                        value["attachments"].push(item);
                    }
                });
            });
        });

        setAttachments(temp_attachments);

        const temp_other_attachments = [...otherAttachments];

        Object.keys(data.attachments).forEach((key) => {
            let custom_obj = {
                key: "",
                remarks: "",
                custom: true,
                required: false,
                attachments: [],
            };

            data.attachments[key].forEach((item, index) => {
                if (key.startsWith("custom")) {
                    const found = temp_attachments.filter(
                        (object) => object.key === key
                    ).length;
                    custom_obj["key"] = key;
                    custom_obj["remarks"] = item.remarks;
                    custom_obj["attachments"].push(item);
                    if (found === 0) {
                        temp_other_attachments.push(custom_obj);
                    }
                }
            });
        });
        setOtherAttachments(temp_other_attachments);

        setDeliverable(data);
        // setDisableField(
        //     data.status === 'SUBMITTED' || data.status === 'APPROVED'
        //         ? true
        //         : false
        // );

        reset({
            zone: data.zone,
            prepared_by: {
                signature_options: data.prepare_details?.signature_options,
                signature_details: data.prepare_details?.signature_details,
                signature: data.prepare_signature,
                name: data.prepare_details?.name,
            },
            approved_by: data.approvals?.MA?.details
                ? {
                      signature_options:
                          data.approvals?.MA?.details.signature_options,
                      signature_details:
                          data.approvals?.MA?.details.signature_details,
                      name: data.approvals?.MA?.details.name,
                      signature: data.approvals?.MA?.signature,
                  }
                : hasRoleCollection.isManagingAgent,
        });
        // eslint-disable-next-line
    }, [id, reset]);

    const getDeliverableSummary = useCallback(
        async (param = null) => {
            try {
                const response = await deliverablesApi.getDeliverableSummary(
                    id
                );

                if (response.status === 200) {
                    setDeliverableSummary(response.data.data);
                }
            } catch (error) {
                notyf.open({
                    type: "danger",
                    message: "Something went wrong with the server",
                });
            }
        },
        [notyf]
    );

    useEffect(() => {
        setAttachments([]);
        getDeliverables();
        getDeliverableSummary();
    }, [getDeliverables]);

    const openDeleteModal = (id, key) => {
        setModalInfo({
            key: key,
            id: id,
            notifMsg: "Are you sure you want to delete this file?",
            open: true,
            severity: "danger",
        });
    };

    const removeFile = () => {
        const temp_attachments = [...attachments];
        const index = temp_attachments[modalInfo.key]["attachments"].findIndex(
            (object) => object.id === modalInfo.id
        );
        if (index === -1) {
            temp_attachments[modalInfo.key]["attachments"].splice(index, 1);
        }

        setAttachments(temp_attachments);
    };

    const deleteFile = async (id, key) => {
        try {
            const response = await deleteAttachment(id);
            if (response.data.status === "SUCCESS") {
                notyf.open({
                    type: "success",
                    message: "Successfully deleted",
                });
                removeFile(id, key);
                window.location.reload();
            }
        } catch (error) {}
    };

    const CreateComponent = useCallback(() => {
        return (
            <>
                {attachments?.map((item, index) => {
                    return (
                        <Col md={6} key={index}>
                            <Form.Group className="mb-5">
                                <Form.Label className="font-weight-bold mb-3">
                                    {item?.remarks}
                                </Form.Label>
                                {(create ||
                                    deliverable.status === "DRAFT" ||
                                    deliverable?.status === "REJECTED") && (
                                    <UploaderComponent
                                        autoUpload={false}
                                        ref={(uploadData) => {
                                            if (uploadData) {
                                                item.upload_data = uploadData;
                                            }
                                        }}
                                        cssClass={
                                            item.required && item.file_count < 1
                                                ? "is-invalid"
                                                : ""
                                        }
                                        maxFileSize={524288000}
                                    />
                                )}
                                {item.required && item.file_count < 1 && (
                                    <small className="text-danger">
                                        This field is required
                                    </small>
                                )}
                                {item.attachments.map((file, index2) => {
                                    return (
                                        <div
                                            className="border-dashed mb-2"
                                            key={index2}
                                        >
                                            <Row className="cursor-pointer">
                                                <Col md={10}>
                                                    <FontAwesomeIcon
                                                        icon={faFile}
                                                        className="d-inline-block me-2 text-secondary"
                                                    />
                                                    <p
                                                        className="mb-0 d-inline-block me-2 text-secondary"
                                                        onClick={() =>
                                                            window.open(
                                                                file.full_path
                                                            )
                                                        }
                                                    >
                                                        {formatFileName(
                                                            file.file_name
                                                        )}
                                                    </p>
                                                </Col>
                                                <Col
                                                    md={2}
                                                    className="text-end"
                                                >
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                Download
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faDownload
                                                                }
                                                                className="me-2"
                                                                onClick={() =>
                                                                    window.open(
                                                                        file.full_path
                                                                    )
                                                                }
                                                            />
                                                        </span>
                                                    </OverlayTrigger>
                                                    {deliverable.status ===
                                                        "DRAFT" &&
                                                        !file?.is_auto && (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        Delete
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faTrash
                                                                        }
                                                                        className="me-2"
                                                                        onClick={() =>
                                                                            openDeleteModal(
                                                                                file.id,
                                                                                item.key
                                                                            )
                                                                        }
                                                                    />
                                                                </span>
                                                            </OverlayTrigger>
                                                        )}
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                })}
                            </Form.Group>
                        </Col>
                    );
                })}
            </>
        );
    }, [create, attachments, deliverable]);

    const ApprovalComponent = () => {
        if (deliverable.status === "SUBMITTED") {
            return (
                <Row className="pt-4">
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label className="font-weight-bold d-block">
                                Status
                            </Form.Label>
                            <Controller
                                control={control}
                                name="approval_status"
                                defaultValue="APPROVED"
                                render={({ field: { value, onChange } }) => (
                                    <>
                                        <Form.Check
                                            label="Approve"
                                            type="radio"
                                            value="APPROVED"
                                            className="d-inline-block me-4"
                                            onChange={onChange}
                                            checked={value === "APPROVED"}
                                        />
                                        <Form.Check
                                            label="Reject"
                                            type="radio"
                                            value="REJECTED"
                                            className="d-inline-block me-4"
                                            onChange={onChange}
                                            checked={value === "REJECTED"}
                                        />
                                    </>
                                )}
                            />

                            <Controller
                                control={control}
                                name="reject_remarks"
                                defaultValue=""
                                render={({ field: { value, onChange } }) => (
                                    <Form.Control
                                        className="mt-3"
                                        type="text"
                                        as="textarea"
                                        rows={4}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    };

    return (
        <React.Fragment>
            <Helmet title={pageTitle} />
            <Row>
                <Col md={6}>
                    <h4 className="mb-3">{pageTitle}</h4>
                </Col>
            </Row>
            <Card>
                <Card.Body>
                    <Form>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <h6>Zone</h6>
                                    <p> {deliverable?.zone_name} </p>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <h6>Submission</h6>
                                <p>
                                    {" "}
                                    {deliverable?.year} -
                                    {deliverable?.month_name}{" "}
                                </p>
                            </Col>

                            <Col md={4}>
                                <h6>Status</h6>
                                <p
                                    className={
                                        deliverable?.status === "REJECTED"
                                            ? "text-danger"
                                            : ""
                                    }
                                >
                                    {create
                                        ? "Draft"
                                        : toTitleCase(deliverable?.status)}
                                </p>
                            </Col>
                            {deliverable?.status === "REJECTED" && (
                                <Col md={6}>
                                    <h6>Rejection reason</h6>
                                    <p>{deliverable.reject_remarks}</p>
                                </Col>
                            )}
                        </Row>
                        <hr />
                        <Row>
                            <Col md={6}>
                                <Form.Label className="font-weight-bold">
                                    Inspection summary details
                                </Form.Label>
                                <Table bordered size="md">
                                    <tbody>
                                        <tr>
                                            <th>Submitted</th>
                                            <td className="text-end">
                                                {deliverableSummary?.submitted ||
                                                    0}
                                            </td>
                                            <td>
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip>
                                                            View submitted
                                                        </Tooltip>
                                                    }
                                                >
                                                    <a
                                                        href={`/inspection-checklist/inspection-servicing?status=SUBMITTED&category=${deliverableSummary?.category}&start_date=${deliverableSummary?.start_date}&end_date=${deliverableSummary?.end_date}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="btn-link pe-1"
                                                    >
                                                        View
                                                    </a>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Acknowledged</th>
                                            <td className="text-end">
                                                {deliverableSummary?.acknowledged ||
                                                    0}
                                            </td>
                                            <td>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            View acknowledged
                                                        </Tooltip>
                                                    }
                                                >
                                                    <a
                                                        href={`/inspection-checklist/inspection-servicing?status=ACKNOWLEDGED&category=${deliverableSummary?.category}&start_date=${deliverableSummary?.start_date}&end_date=${deliverableSummary?.end_date}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="btn-link pe-1"
                                                    >
                                                        View
                                                    </a>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Approved</th>
                                            <td className="text-end">
                                                {deliverableSummary?.approved ||
                                                    0}
                                            </td>
                                            <td>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            View approved
                                                        </Tooltip>
                                                    }
                                                >
                                                    <a
                                                        href={`/inspection-checklist/inspection-servicing?status=APPROVED&category=${deliverableSummary?.category}&start_date=${deliverableSummary?.start_date}&end_date=${deliverableSummary?.end_date}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="btn-link pe-1"
                                                    >
                                                        View
                                                    </a>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <th>
                                                Pending (Submitted +
                                                Acknowledged)
                                            </th>
                                            <td className="text-end">
                                                {deliverableSummary?.pending_approval ||
                                                    0}
                                            </td>
                                            <td></td>
                                        </tr> */}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row className="pt-4">
                            <CreateComponent />
                            {otherAttachments.map((item, index) => {
                                return (
                                    <Col md={6} key={index}>
                                        <Form.Group className="mb-5">
                                            {create ||
                                            deliverable.status === "DRAFT" ||
                                            deliverable?.status ===
                                                "REJECTED" ? (
                                                <>
                                                    <InputGroup>
                                                        <Form.Control
                                                            name={item.key}
                                                            type="text"
                                                            className="font-weight-bold mb-1"
                                                            placeholder={
                                                                item.placeholder
                                                            }
                                                            value={item.remarks}
                                                            onChange={(e) => {
                                                                const temp_other_attachment =
                                                                    [
                                                                        ...otherAttachments,
                                                                    ];
                                                                temp_other_attachment[
                                                                    index
                                                                ]["remarks"] =
                                                                    e.target.value;
                                                                setOtherAttachments(
                                                                    temp_other_attachment
                                                                );
                                                            }}
                                                        />
                                                        <InputGroup.Text
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                remove(index)
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                className="me-2"
                                                            />
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                    <UploaderComponent
                                                        autoUpload={false}
                                                        ref={(uploadData) => {
                                                            if (uploadData) {
                                                                item.upload_data =
                                                                    uploadData;
                                                            }
                                                        }}
                                                        cssClass={
                                                            item.required &&
                                                            item.file_count < 1
                                                                ? "is-invalid"
                                                                : ""
                                                        }
                                                        maxFileSize={524288000}
                                                    />
                                                </>
                                            ) : (
                                                <Form.Label className="font-weight-bold">
                                                    {item.remarks}
                                                </Form.Label>
                                            )}
                                            {item.attachments?.map(
                                                (file, index2) => {
                                                    return (
                                                        <div
                                                            className="border-dashed mb-2"
                                                            key={index2}
                                                        >
                                                            <Row className="cursor-pointer">
                                                                <Col md={10}>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faFile
                                                                        }
                                                                        className="d-inline-block me-2 text-secondary"
                                                                    />
                                                                    <p
                                                                        className="mb-0 d-inline-block me-2 text-secondary"
                                                                        onClick={() =>
                                                                            window.open(
                                                                                file.full_path
                                                                            )
                                                                        }
                                                                    >
                                                                        {formatFileName(
                                                                            file.file_name
                                                                        )}
                                                                    </p>
                                                                </Col>
                                                                <Col
                                                                    md={2}
                                                                    className="text-end"
                                                                >
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                Download
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faDownload
                                                                                }
                                                                                className="me-2"
                                                                                onClick={() =>
                                                                                    window.open(
                                                                                        file.full_path
                                                                                    )
                                                                                }
                                                                            />
                                                                        </span>
                                                                    </OverlayTrigger>

                                                                    {deliverable.status ===
                                                                        "DRAFT" &&
                                                                        !file?.is_auto && (
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                        Delete
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    <FontAwesomeIcon
                                                                                        icon={
                                                                                            faTrash
                                                                                        }
                                                                                        onClick={() =>
                                                                                            openDeleteModal(
                                                                                                file.id,
                                                                                                item.key
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        )}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    );
                                                }
                                            )}
                                            {item.required &&
                                                item.file_count < 1 && (
                                                    <small className="text-danger">
                                                        This field is required
                                                    </small>
                                                )}
                                        </Form.Group>
                                    </Col>
                                );
                            })}
                            {(create || deliverable.status === "DRAFT") && (
                                <Col md={6}>
                                    <Form.Label></Form.Label>
                                    <div className="mt-3 p-3 text-center border-dashed">
                                        <Button
                                            className="me-2"
                                            variant="light"
                                            size="sm"
                                            onClick={() => addMore()}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                className="feather me-2"
                                            />
                                            Add more
                                        </Button>
                                    </div>
                                </Col>
                            )}
                        </Row>
                        {deliverable.histories?.length > 0 && (
                            <DeliverableStatusHistory
                                histories={deliverable.histories}
                            />
                        )}

                        {hasRoleCollection?.isManagingAgent && (
                            <ApprovalComponent errors={errors} />
                        )}
                        <Row className="pt-4">
                            <SignaturesForm
                                control={control}
                                canSign={
                                    hasRoleCollection.isTermContractor &&
                                    !state?.view
                                }
                                label="Prepared by"
                                name="prepared_by"
                                selectedSignatureOption={tcSignatureOption}
                                setValue={setValue}
                            />

                            <SignaturesForm
                                control={control}
                                canSign={
                                    hasRoleCollection.isManagingAgent &&
                                    !state?.view
                                }
                                label="Certificate by"
                                name="approved_by"
                                selectedSignatureOption={maSignatureOption}
                                setValue={setValue}
                            />
                        </Row>

                        <Row className="pt-4">
                            <Col md={12} className="text-center">
                                {(deliverable.status === "DRAFT" ||
                                    deliverable?.status === "REJECTED") &&
                                    !hasRoleCollection?.isAdmin && (
                                        <>
                                            <Button
                                                className="me-2"
                                                variant="secondary"
                                                onClick={handleSubmit(
                                                    draftHandler
                                                )}
                                                disabled={loading}
                                            >
                                                {loading
                                                    ? "Please wait ..."
                                                    : "Draft"}
                                            </Button>
                                            <Button
                                                className="me-2"
                                                variant="primary"
                                                onClick={handleSubmit(
                                                    submitHandler
                                                )}
                                                disabled={loading}
                                            >
                                                {loading
                                                    ? "Please wait ..."
                                                    : "Submit"}
                                            </Button>
                                        </>
                                    )}

                                {deliverable.status === "SUBMITTED" &&
                                    !hasRoleCollection?.isAdmin && (
                                        <Button
                                            className="me-2"
                                            variant="info"
                                            onClick={handleSubmit(
                                                confirmHandler
                                            )}
                                            disabled={loading}
                                        >
                                            {loading
                                                ? "Please wait ..."
                                                : "Submit"}
                                        </Button>
                                    )}
                                <Button
                                    className="me-2"
                                    variant="link"
                                    onClick={() => {
                                        navigate(listLink);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            <DeleteModal
                api={deleteFile}
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
            />
        </React.Fragment>
    );
}
